import React from "react";
import axios from "axios";

import {API_DOMEN} from "../../../api";

import moment from "moment";
import "moment/locale/ru";

import ShopPageModal from "./ShopPageModal";
import ShopPageAwoForm from "./ShopPageAwoForm";

const ShopPageMain2Image = ({
    subtitle,
    title,
    description,
    range,
    minDate,
    maxDate,
    date,
    typeForm,
    idAwo,
    vkUrl,
    telegramUrl,
    image,
    tagIdAwo,
    redirectFormBotId,
    redirectFormBotName,
    awoFormCustom,
    awoFormCustomId,
    awoFormCustomUrlScript,
}) => {
    const [stateModalShopPage, setStateModalShopPage] = React.useState(false);

    React.useEffect(() => {
        document.body.addEventListener("click", handTeacherModalBool);
    }, []);

    const ShopPageModalRef = React.useRef();

    const toggleModal = () => {
        setStateModalShopPage(!stateModalShopPage);
    };

    if (stateModalShopPage === true) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "visible";
    }

    const handTeacherModalBool = (e) => {
        if (e.target === ShopPageModalRef.current) {
            setStateModalShopPage(false);
        }
    };

    const [isSend, setIsSend] = React.useState(false);

    const onSubmit = (data) => {
        const utm_partner = parseInt(localStorage.getItem("utm_partner"));
        const utm_source = localStorage.getItem("utm_source");
        const utm_medium = localStorage.getItem("utm_medium");
        const utm_campaign = localStorage.getItem("utm_campaign");
        const utm_content = localStorage.getItem("utm_content");
        const utm_term = localStorage.getItem("utm_term");

        axios
            .post(
                typeForm === "timetable"
                    ? `${API_DOMEN}/goods/subsEmail`
                    : `${API_DOMEN}/goods/getsite`,
                {
                    ...data,
                    message: "",
                    tagIdAwo: tagIdAwo,
                    idAwo: idAwo,
                    utm_partner,
                    utm_source,
                    utm_medium,
                    utm_campaign,
                    utm_content,
                    utm_term,
                }
            )
            .then(() => {
                if (redirectFormBotId && redirectFormBotName) {
                    window.location.href = `https://t.me/${redirectFormBotName}?start=${btoa(
                        `${data.email}|${redirectFormBotId}`
                    )}`;
                } else {
                    setIsSend(true);
                }
            });
    };

    React.useEffect(() => {
        if (awoFormCustom == "1") {
            document
                .querySelector("#awo-form")
                .setAttribute("src", awoFormCustomUrlScript);
        }
    }, []);

    return (
        <>
            <section
                className={`shop-page-main2-image`}
                id="shop-page-main2-image">
                <div className="container">
                    <div className="shop-page-main2-image-wrapper">
                        <div className="shop-page-main2-image-text">
                            <p
                                className={`shop-page-main2-image-text__subtitle`}>
                                {subtitle}
                            </p>
                            <h1
                                className={`shop-page-main2-image-text__title`}
                                dangerouslySetInnerHTML={{
                                    __html: title,
                                }}></h1>
                            <p
                                className={`shop-page-main2-image-text__description`}
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}></p>

                            <div className="circle-wrapper main-circle-wrapper">
                                <div className="circle-regular main-circle1"></div>
                                <div
                                    className={`circle-bold main-circle2`}></div>
                            </div>

                            {range ? (
                                <div className="shop-page-main2-image-text-date">
                                    <div className="shop-page-main2-image-text-date-left">
                                        <span
                                            className={`shop-page-main2-image-text__date-range`}>
                                            <b>Дата старта:</b>
                                        </span>
                                    </div>
                                    <div className="shop-page-main2-image-text-date-right">
                                        <span
                                            className={`shop-page-main2-image-text__date-range`}>
                                            <b>с:</b>{" "}
                                            {moment(
                                                minDate,
                                                "YYYY-MM-DD, HH:mm"
                                            )
                                                .locale("ru")
                                                .format("DD MMMM, HH:mm")}
                                        </span>
                                        <span
                                            className={`shop-page-main2-image-text__date-range`}>
                                            <b>до:</b>{" "}
                                            {moment(
                                                maxDate,
                                                "YYYY-MM-DD, HH:mm"
                                            )
                                                .locale("ru")
                                                .format("DD MMMM, HH:mm")}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={`shop-page-main2-image-text-date`}>
                                    <div className="shop-page-main2-image-text-date-left">
                                        <span
                                            className={`shop-page-main2-image-text__date`}>
                                            <b>Дата старта:</b>
                                        </span>
                                    </div>
                                    <div className="shop-page-main2-image-text-date-right">
                                        <span
                                            className={`shop-page-main2-image-text__date`}>
                                            {moment(date, "YYYY-MM-DD, HH:mm")
                                                .locale("ru")
                                                .format("DD MMMM, HH:mm")}
                                        </span>
                                    </div>
                                </div>
                            )}

                            {vkUrl || telegramUrl ? (
                                <button
                                    onClick={toggleModal}
                                    className={`btn-bold_color shop-page-main2-image-text__btn`}>
                                    Записаться
                                </button>
                            ) : !isSend ? (
                                awoFormCustom == "1" ? (
                                    <button
                                        onClick={() =>
                                            window.awoOpenFormModal(
                                                awoFormCustomId
                                            )
                                        }
                                        className={`btn-bold_color shop-page-main2-image-text__btn`}>
                                        Записаться
                                    </button>
                                ) : (
                                    <ShopPageAwoForm onSubmit={onSubmit} />
                                )
                            ) : (
                                <div className="shop-page-form-thank">
                                    <h3 className="shop-page-form-thank__title">
                                        Спасибо за заявку!
                                    </h3>

                                    <p className="shop-page-form-thank__description">
                                        С вами скоро свяжется наш менеджер
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className="shop-page-main2-image-cover">
                            <img
                                src={image}
                                alt=""
                                className="shop-page-main2-image-cover__image"
                            />
                        </div>
                    </div>
                </div>
            </section>

            {vkUrl || telegramUrl ? (
                <ShopPageModal
                    close={toggleModal}
                    state={stateModalShopPage}
                    ShopPageModalRef={ShopPageModalRef}
                    vkUrl={vkUrl}
                    telegramUrl={telegramUrl}
                    idAwo={idAwo}
                    onSubmit={onSubmit}
                />
            ) : null}
        </>
    );
};

export default ShopPageMain2Image;
