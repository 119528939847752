import React from "react";
import {Field, reduxForm} from "redux-form";

import validate from "./validate";

import {RenderCheckboxPolycy, RenderInput} from "../../";

let ShopPageModalForm = ({
    size,
    invalid,
    submitting,
    pristine,
    handleSubmit,
}) => {
    return (
        <form
            className={`shop-page-modal-form ${size}`}
            onSubmit={handleSubmit}>
            <div className="shop-page-modal-form-middle">
                <div className={`shop-page-modal-form-block-wrapper ${size}`}>
                    <div className="input shop-page-modal-form-input-wrapper">
                        <div className="shop-page-form-input">
                            <Field
                                component={RenderInput}
                                size={size}
                                type="email"
                                name="email"
                                label="Email"
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className={`btn-bold_color shop-page-form__btn ${
                            !pristine && !submitting && !invalid
                                ? ""
                                : "btn-bold_color_disabled"
                        }`}
                        disabled={invalid || submitting || pristine}>
                        Записаться
                    </button>
                </div>
            </div>
            <div className="checkbox-wrapper shop-page-checkbox">
                <Field
                    component={RenderCheckboxPolycy}
                    type="checkbox"
                    name="confirmation"
                    id={`shop-page__checkbox-${Math.floor(
                        Math.random() * 10000000
                    )}`}
                    size={size}
                />
            </div>
        </form>
    );
};

ShopPageModalForm = reduxForm({
    form: "shop_page_modal_form",
    validate,
})(ShopPageModalForm);

export default ShopPageModalForm;
